import React, { FC, useCallback, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import styles from './questionnaire.module.scss';
import Spinner from '../../components/spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header';
import Button from '../../components/button';
import { DocTypes, getDocPdf, getDocumentInfo } from '../../api/apiService';
import NoData from '../../components/no-data';

const QuestionnairePage: FC<any> = () => {
  const [spinner, setSpinner] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { documentId } = useParams();
  const [pdf, setPdf] = useState<File>();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [questionnaire, setQuestionnaire]: any[] = useState(null);

  const getPdf = useCallback(async (documentId: string) => {
    const pdf = await getDocPdf(documentId, DocTypes.QUESTIONNAIRE_ANSWER);
    setPdf(pdf);
  }, []);

  const getQuestionnaire = useCallback(async () => {
    if (!documentId) {
      navigate('/failed');
      return;
    }

    if (questionnaire) {
      return;
    }
    setSpinner(true);
    localStorage.removeItem('signed');
    localStorage.removeItem('documentId');

    try {
      const questionnaire = await getDocumentInfo({
        docId: documentId,
        docType: DocTypes.QUESTIONNAIRE_ANSWER,
      });

      if (!questionnaire) {
        navigate('/failed');
        return;
      }

      await getPdf(documentId).catch(() => {
        sessionStorage.removeItem('documentId');
        navigate('/failed');
      });

      localStorage.setItem('documentId', documentId);

      if (questionnaire.signed) {
        localStorage.setItem('signed', questionnaire.signed);
        navigate(`/success/${DocTypes.QUESTIONNAIRE_ANSWER}`);
      } else {
        localStorage.removeItem('signed');
      }

      setQuestionnaire(questionnaire);
      setSpinner(false);
    } catch (e) {
      navigate('/failed');
      setSpinner(false);
    }
  }, [documentId, questionnaire, navigate, getPdf]);

  const handleClickNext = useCallback(async () => {
    if (!documentId) {
      navigate(`/sign/${DocTypes.QUESTIONNAIRE_ANSWER}`);
    } else {
      navigate(`/sign/${DocTypes.QUESTIONNAIRE_ANSWER}`);
    }
  }, [documentId, navigate]);

  useEffect(() => {
    getQuestionnaire();

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [documentId, questionnaire, getQuestionnaire, navigate]);

  return (
    <>
      {spinner && <Spinner fullPage={true}></Spinner>}
      <Header></Header>
      {pdf && (
        <div className="container">
          <div className={styles.title}>{t('sign_document')}</div>
          <div className={styles.pdf}>
            {pdf && (
              <Document file={pdf} noData={<NoData />} loading={<Spinner fullPage={true} />}>
                <Page
                  pageNumber={1}
                  width={windowWidth - 40}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
            )}
          </div>
        </div>
      )}
      <div className="container">
        <div className={styles.buttons}>
          <Button onClick={handleClickNext}>{t('next_step')}</Button>
        </div>
      </div>
    </>
  );
};

export default QuestionnairePage;
