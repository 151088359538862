import React, { useCallback, useEffect } from 'react';
import styles from './success.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import Socials from '../../components/socials';
import { DocTypes, getDocPdf } from '../../api/apiService';
import fileDownload from 'js-file-download';
import { useNavigate, useParams } from 'react-router-dom';

enum FileNames {
  TRANSACTION = 'receipt',
  QUESTIONNAIRE_ANSWER = 'KYC questionnaire',
}

const SuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { docType } = useParams();
  const documentId = localStorage.getItem('documentId');
  const signed = localStorage.getItem('signed');

  const downloadPdf = useCallback(async () => {
    if (documentId && docType) {
      const receipt: any = await getDocPdf(documentId, DocTypes[docType]);
      fileDownload(receipt, `${FileNames[docType]}.pdf`);
    }
  }, [documentId, docType]);

  useEffect(() => {
    if (!signed || !docType || !documentId) {
      navigate('/failed');
    }
  }, [navigate, documentId, signed, docType]);

  return (
    <div className={styles.success}>
      {docType}
      <div className={styles.top}>
        <div>
          <strong className="title">{t('success')}</strong>
        </div>
      </div>

      <div className={styles.main}>
        <strong>
          {docType === DocTypes.TRANSACTION
            ? t('you_successfully_signed_receipt')
            : t('thank_you_for_sign')}
        </strong>
        <p>
          {docType === DocTypes.TRANSACTION
            ? t('press_button_to_download_receipt')
            : t('press_button_to_download_questionnaire')}
        </p>

        <div className={styles.warning}>{t('download_link_available_72_hours')}</div>

        <div className={styles.buttons}>
          <Button disabled={!documentId} className="full-width" onClick={downloadPdf}>
            {t('download_receipt')}
          </Button>
          <Button
            className="full-width secondary"
            onClick={() => (window.location.href = 'https://stbunion.app.link/B0m5mKE8idb')}
          >
            {t('go_to_stb_app')}
          </Button>
        </div>

        <div className={styles.socials}>{t('visit_us_online')}</div>
        <Socials></Socials>
      </div>
    </div>
  );
};

export default SuccessPage;
